import { ReactNode, useMemo } from "react";
import { useContentful } from "react-contentful";
import { EntityTypes, MarketingCopyFields, Region } from "../models/contentful";
import {
  renderAssetHyperlink,
  renderRichTextWithPlaceholdersAndImages,
} from "../utils/contentful";
import { useRouter } from "next/router";

export interface MarketingContent {
  data?: {
    region: Region;
    path: string;
    title?: string;
    content: ReactNode;
    icon?: ReactNode;
  };
  error?: unknown;
  fetched: boolean;
  loading: boolean;
}

export const useMarketingCopy = (): MarketingContent => {
  const { pathname } = useRouter();

  const { data, error, fetched, loading } = useContentful({
    contentType: EntityTypes.MarketingCopy,
    query: {
      "fields.region": process.env.NEXT_PUBLIC_QP_TERRITORY,
      "fields.path": pathname,
    },
    include: 1,
    parser: getFieldsFromFirstItem,
  });

  const fields = data as MarketingCopyFields;

  const content = useMemo(() => {
    if (!fields?.content) {
      return null;
    }

    return renderRichTextWithPlaceholdersAndImages(fields?.content);
  }, [fields?.content]);

  const icon = useMemo(() => {
    if (!fields?.icon) {
      return null;
    }

    return renderAssetHyperlink(fields.icon);
  }, [fields?.icon]);

  return {
    data: !!fields ? {
      region: fields?.region,
      path: fields?.path,
      title: fields?.title,
      content,
      icon,
    } : undefined,
    error,
    fetched,
    loading,
  };
};

const getFieldsFromFirstItem = (data: any): any | null =>
  data?.items?.length ? data.items[0]?.fields : null;
