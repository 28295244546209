import { OnboardingMerchant } from "../models/onboardingMerchant";
import { isMerchantDeclined } from "../utils/decline";

const hasDueDiligenceResult = (merchant: OnboardingMerchant): boolean =>
  !!merchant &&
  merchant.dueDiligenceSucceeded !== undefined &&
  merchant.dueDiligenceSucceeded !== null;

const hasReachedDueDiligenceStage = (merchant: OnboardingMerchant): boolean =>
  !!merchant && (merchant.isInReview || hasDueDiligenceResult(merchant));

export const businessInfoGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant && (!hasReachedDueDiligenceStage(merchant) || !merchant.businessInfoCompleted);

export const complianceInfoGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant && !!merchant.businessInfoCompleted && (!hasReachedDueDiligenceStage(merchant) || !merchant.complianceInfoCompleted);

export const stripeConnectGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant && !!merchant.complianceInfoCompleted && (!hasReachedDueDiligenceStage(merchant) || !merchant.stripeAccountCompleted);

export const dueDiligenceGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant && merchant.isInReview;

export const msaGuard = (merchant: OnboardingMerchant): boolean => {
  const isMsaReady =
    hasDueDiligenceResult(merchant) && !!merchant.dueDiligenceSucceeded;

  return isMsaReady && !merchant.msaSigned;
};

export const widgetIntegrationGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant &&
  !merchant.isInReview &&
  !!merchant.dueDiligenceSucceeded &&
  !!merchant.msaSigned;

export const pluginIntegrationGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant &&
  !merchant.isInReview &&
  !!merchant.dueDiligenceSucceeded &&
  !!merchant.msaSigned;

export const integrationsWhiteGloveGuard = (merchant: OnboardingMerchant): boolean =>
  !!merchant &&
  merchant.msaSigned &&
  merchant.isWhiteGloveExperience;

export const integrationCompleteGuard = (
  merchant: OnboardingMerchant,
): boolean =>
  !!merchant && !merchant.isInReview && !!merchant?.integrationCompleted;

export const merchantDeclinedGuard = (merchant: OnboardingMerchant): boolean =>
  isMerchantDeclined(merchant);
