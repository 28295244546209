import { FunctionComponent } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { colors } from "merchant-core";
import { useMarketingCopy } from "../hooks/useMarketingCopy";
import { StyledPaper } from "merchant-core";

const StyledStack = styled(Stack)({
  alignContent: "center",
  justifyContent: "center",
});

export const MarketingCopy: FunctionComponent = () => {
  const { data, fetched, loading } = useMarketingCopy();

  if (loading || !fetched || !data?.content) {
    return <></>;
  }

  const { title, content, icon } = data;

  return (
    <StyledPaper sx={{
      p: 2,
      backgroundColor: colors.PURPLE_LIGHTER,
      color: colors.BLACK
    }}>
      <Stack direction="row" spacing={2}>
        <StyledStack sx={{ maxWidth: "50px" }}>{icon}</StyledStack>
        <StyledStack>
          <Typography variant="h2" mb="0">
            {title}
          </Typography>
          {content}
        </StyledStack>
      </Stack>
    </StyledPaper>
  );
};
