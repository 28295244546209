import { FunctionComponent } from "react";
import { PlaceholderType } from "../models/contentful";
import { CopyField } from "merchant-core";
import { useOnboardingMerchant } from "../hooks/api/useOnboardingMerchant";

export type PlaceholderProps = {
  name: string;
  type: PlaceholderType;
};

export const Placeholder: FunctionComponent<PlaceholderProps> = ({
  name,
  type,
}) => {
  const { data } = useOnboardingMerchant();
  if (!data) {
    return <></>;
  }

  const merchantId = data.id || "";
  const clientId = data?.apiKey?.clientId || "";
  const clientSecret = data?.apiKey?.clientSecret || "";

  switch (type) {
    case PlaceholderType.MerchantId:
      return <CopyField title={name || "Merchant ID"} value={merchantId} />;

    case PlaceholderType.ClientId:
      return <CopyField title={name || "Client ID"} value={clientId} />;

    case PlaceholderType.ClientSecret:
      return <CopyField title={name || "Client Secret"} value={clientSecret} />;

    case PlaceholderType.ApiKey:
      return <CopyField title={name || "API Key"} value={clientSecret} />;
  }
};
