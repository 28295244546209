type PrimaryIndustry =
  | "Home & Furniture"
  | "Jewelry & Accessories"
  | "Beauty, Health & Wellness"
  | "Clothing"
  | "Food & Beverage"
  | "Kids & Babies"
  | "Pets"
  | "Footwear"
  | "Travel, Leisure & Experiences"
  | "Sports, Fitness & Outdoors"
  | "Education"
  | "Marketplace"
  | "Financial Services"
  | "Utilities"
  | "Entertainment"
  | "Health Care"
  | "Legal Services"
  | "Technology & Media"
  | "Automotive"
  | "Art & Design";

export type Industry = {
  primary: PrimaryIndustry;
  secondary: string[];
};

export const UNSUPPORTED_SECONDARY_INDUSTRIES = [
  "Weapons and Ammunition",
  "Adult",
  "CBD",
  "E-Cigarettes",
];

// Primary and secondary industries
export const INDUSTRIES: Industry[] = [
  {
    primary: "Home & Furniture",
    secondary: [
      "Appliances",
      "Kitchen",
      "Stationary",
      "Decor",
      "Bedroom & Bathroom",
      "Niche",
      "Garden & Botany",
      "Office",
      "Construction",
    ],
  },
  {
    primary: "Jewelry & Accessories",
    secondary: [
      "Jewelry",
      "Optical",
      "Luggage",
      "Hand Bags",
      "Niche",
      "Watches",
      "Accessories",
      "Hats",
      "Gifts",
    ],
  },
  {
    primary: "Beauty, Health & Wellness",
    secondary: [
      "Optical",
      "Hair",
      "Skincare",
      "Perfume",
      "Cosmetics",
      "Grooming",
      "Supplements",
      "CBD",
      "Adult",
      "Niche",
      "Hair Extensions",
      "Spas/Salons",
      "Eye Wear",
      "Wellness",
      "Consumer Goods",
    ],
  },
  {
    primary: "Clothing",
    secondary: [
      "Plus Size",
      "Activewear",
      "Designer Fashion",
      "Streetwear",
      "Formal",
      "Bridal",
      "Maternity",
      "Swimwear",
      "Intimates",
      "Niche",
      "Accessories",
      "Casual",
      "Denim",
      "Fast Fashion",
    ],
  },
  {
    primary: "Food & Beverage",
    secondary: [
      "Health Food",
      "Niche",
      "Alcohol",
      "Delivery",
      "Grocery",
      "Restaurant",
    ],
  },
  {
    primary: "Kids & Babies",
    secondary: ["Toys", "Strollers & Carriers", "Niche", "Childcare"],
  },
  { primary: "Pets", secondary: ["Grooming", "Accessories", "Pets"] },
  {
    primary: "Footwear",
    secondary: [
      "Activewear",
      "Designer Fashion",
      "Streetwear",
      "Formal",
      "Shoes",
    ],
  },
  {
    primary: "Travel, Leisure & Experiences",
    secondary: [
      "Flights",
      "Ticketing",
      "Amusement Parks",
      "Cruises",
      "Hotels",
      "Trains",
    ],
  },
  {
    primary: "Sports, Fitness & Outdoors",
    secondary: [
      "Equipment",
      "Beach",
      "Niche",
      "Fitness / Gyms",
      "Landscaping",
      "Sports team",
      "e-sports",
    ],
  },
  {
    primary: "Education",
    secondary: ["Learning Materials", "In-Person Learning", "E-learning"],
  },
  {
    primary: "Marketplace",
    secondary: [
      "Home & Furniture",
      "Jewelry & Accessories",
      "Beauty & Health",
      "Clothing",
      "Food & Beverage",
      "Kids & Babies",
      "Footwear",
      "Travel & Experiences",
      "Retail",
      "Entertainment",
      "Art & Design",
    ],
  },
  { primary: "Financial Services", secondary: ["Banking", "Insurance"] },
  { primary: "Utilities", secondary: ["Electric", "Gas", "Water"] },
  {
    primary: "Entertainment",
    secondary: [
      "Cinema",
      "Music venue",
      "Music equipments",
      "Games & Video Games",
      "Consumer Goods",
    ],
  },
  {
    primary: "Health Care",
    secondary: [
      "Dental",
      "Hospital",
      "Medical Devices",
      "Medical Practice",
      "Medical Services",
      "Pharmacy",
      "Clinic",
    ],
  },
  { primary: "Legal Services", secondary: ["Law Practice"] },
  {
    primary: "Technology & Media",
    secondary: [
      "Consumer Electronics",
      "Computer Hardware",
      "Software",
      "Media",
      "Telecommunication",
    ],
  },
  {
    primary: "Automotive",
    secondary: ["Cars", "Bikes / Scooters", "Car Parts", "Tires", "Used Car"],
  },
  {
    primary: "Art & Design",
    secondary: [
      "Museum",
      "Gallery",
      "Art studio",
      "Design Studio",
      "Photography",
    ],
  },
];
