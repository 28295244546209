import { FunctionComponent } from "react";
import { MarketingCopy } from "./MarketingCopy";
import { Paper, Stack } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "next-i18next";
import { ExternalLink } from "merchant-core";
import { useSettings } from "../hooks/useSettings";
import { useOnboardingMerchant } from "../hooks/api/useOnboardingMerchant";
import { LargeMerchantContact } from "./LargeMerchantContact";

const StyledStack = styled(Stack)`
  a::after {
    content: " 🡪";
  }
`;

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation("common");
  const { data: merchant } = useOnboardingMerchant();
  const { isDevelopmentMode } = useSettings();

  return (
    <Stack sx={{ marginBottom: "20px" }}>
      <MarketingCopy />
      <LargeMerchantContact />
      <StyledStack direction="row" spacing={3}>
        <ExternalLink href="https://merchant-help.us.zip.co/hc/en-us">
          {t("footer.help-center")}
        </ExternalLink>
        <ExternalLink href="https://docs.us.zip.co/">
          {t("footer.developer-documentation")}
        </ExternalLink>
      </StyledStack>

      {isDevelopmentMode && merchant && (
        <Paper>
          <pre>
            <code>{JSON.stringify(merchant, null, 4)}</code>
          </pre>
        </Paper>
      )}
    </Stack>
  );
};
