/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { Placeholder } from "../components/Placeholder";
import { Box } from "@mui/material";
import { colors } from "merchant-core";
import { ExternalImage, ExternalLink } from "merchant-core";

export const renderRichTextWithPlaceholdersAndImages = (
  document?: Document,
): ReactNode | null => {
  if (!document) {
    return null;
  }

  return (
    <div className="contentful">
      {documentToReactComponents(document, {
        renderNode: {
          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            const { name, placeholderType } = node.data.target.fields;
            return (
              <Box
                padding="1rem"
                border={`1px solid ${colors.BORDER_GREY}`}
                borderRadius="4px"
              >
                <Placeholder name={name} type={placeholderType} />
              </Box>
            );
          },
          [BLOCKS.EMBEDDED_ASSET]: (node) =>
            renderAssetHyperlink(node?.data?.target),
          [INLINES.HYPERLINK]: (node, children: any) => {
            const { uri, title } = node.data;
            return (
              <ExternalLink href={uri} title={title}>{children}</ExternalLink>
            );
          },
        },
      })}
    </div>
  );
};

export const renderAssetHyperlink = (asset: any): ReactNode | null => {
  if (!asset?.fields?.file?.url) {
    return null;
  }

  const { description, title, file } = asset.fields;
  const { url } = file;
  return <ExternalImage src={url} alt={description || title} />;
};
