import { FunctionComponent } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useOnboardingMerchant } from "../hooks/api/useOnboardingMerchant";
import { colors, StyledPaper, ExternalLink } from "merchant-core";

const MIN_ANNUAL_SALES_LIMIT = 5_000_000;

const StyledStack = styled(Stack)({
  alignContent: "center",
  justifyContent: "center",
});

export const LargeMerchantContact: FunctionComponent = () => {
  const { t } = useTranslation("common");
  const { data, isLoading, isSuccess } = useOnboardingMerchant();

  if (isLoading) {
    return <></>;
  }

  const annualRevenue = data?.businessInfo?.annualRevenue ?? 0;
  const isLargeMerchant = annualRevenue >= MIN_ANNUAL_SALES_LIMIT;
  if (isSuccess && !isLargeMerchant) {
    return <></>;
  }

  return (
    <StyledPaper
      sx={{
        p: 2,
        backgroundColor: colors.GRAY_MEDIUM,
        color: colors.BLACK
      }}
    >
      <Stack direction="row" spacing={2}>
        <StyledStack>
          <Typography>
            {t("footer.large-merchant-callout-1")}{" "}
            <ExternalLink href="mailto:partners@us.zip.co">here</ExternalLink>
            {". "}
            {t("footer.large-merchant-callout-2")}
          </Typography>
        </StyledStack>
      </Stack>
    </StyledPaper>
  );
};
